.leaflet-container {
    width: 100%;
    height: 100vh;
    z-index: 0;
    cursor: default;
    margin: 0;
    padding: 0;
    max-width: 100vw;    
}

.leaflet-control-layers-toggle {
    display: none;
}

.leaflet-control-zoom {
    display: none;
}

.leaflet-container.crosshair-cursor-enabled {
    cursor: default;
}

.leaflet-container .leaflet-control-attribution{
    display: none;
}

.btn-off {
    display: none!important;
}

a#polyline-measure-control {
    top: 352px;
    left: 32px;
    z-index: 2000;
    position: fixed;
}

a#unitControlId {
    top: 368px;
    left: 8px;
    width: 24px;
    height: 24px;
    position: fixed;
    z-index: 1999;
    min-height: 24px;
}

a.polyline-measure-controlOnBgColor {
    background-color: '#3f51b5';
  }
  
a.polyline-measure-controlOnBgColor:hover {
    background-color: #3f51b5!important;
  }
  
.layer-off {
    display: none;
}